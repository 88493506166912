import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import withDynamicWidth from './withDynamicWidth';
import withTheme from './withTheme';

const DayNightSplitChart = ({ data, width, themeLayout }) => {
    return (
        <Plot
            data={data}
            layout={{
                width: width,
                height: 400,
                xaxis: { 
                    title: { font: { size: 18 } }, 
                    showgrid: false, 
                    zeroline: false, 
                    tickformat: '%d %b %Y',
                    dtick: 'D1',
                    type: 'date',
                    calendar: 'gregorian',
                    tickangle: 45,
                    hoverformat: '%d %b %Y',
                    rangeslider: { visible: false },
                    showspikes: false,
                    spikemode: false,
                    spikethickness: 0
                },
                yaxis: { 
                    title: { text: 'kWH', font: { size: 18 } }, 
                    showgrid: true, 
                    zeroline: true 
                },
                plot_bgcolor: '#ffffff',
                barmode: 'stack',
                paper_bgcolor: '#ffffff',
                scrollZoom: true,
                ...themeLayout,
            }}
        />
    );
};

DayNightSplitChart.propTypes = {
    data: PropTypes.array.isRequired,
    width: PropTypes.number.isRequired,
    themeLayout: PropTypes.object.isRequired,
};

export default withDynamicWidth(withTheme(DayNightSplitChart));
