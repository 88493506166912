import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Form, Input, Select, message, Skeleton, Alert, notification,Switch, Row, Col, Modal, Flex, Statistic, Result, Card, Tabs, Spin } from "antd";
// import { api_endpoint } from "./apiConfig";
import { pushUserData, pushBusinessData, registerBusiness, fetchMonitors, inviteUserToBusiness, registerMonitorToAccount, confirmTransaction, fetchCreditBalance, claimVoucherCode } from "../services/apiService";
import { debounce } from 'lodash';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useLoadScript } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from '../constants'; // Adjust the import path as needed
import { PaystackButton } from 'react-paystack';

import config from '../config';
const { Option } = Select;
const { TabPane } = Tabs;

const libraries = ['places'];

import logger from '../utils/logger';
import FormItem from "antd/es/form/FormItem";
import FeedbackCard from "./feedbackCard";
// import DebugItem from "./debugItem";
logger.enableLogging('Profile');



function Profile({ businessData, userData, setBusinessData, setUserData, setUserEnabledDarkMode }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [email, setEmail] = useState('');
  const [newMonitorID, setNewMonitorID] = useState('');

  const [address, setAddress] = useState(null);
  const [customAddress, setCustomAddress] = useState(null);

  const [numMonitors, setNumMonitors] = useState(0);

  const [preferences, setPreferences] = useState({
    dataAggregationLevel: 'minutely',
    darkMode: false,
  });
  const [isChanged, setIsChanged] = useState(false);
  // const [userEnabledDarkMode, setUserEnabledDarkMode] = useState(false);

  useEffect(() => {
    // Retrieve preferences from localStorage
    const dataAggregationLevel = localStorage.getItem('dataAggregationLevel') || 'minutely';
    const darkMode = localStorage.getItem('darkMode') === 'true'; // Convert string to boolean
    setUserEnabledDarkMode(darkMode);
    setPreferences({ dataAggregationLevel, darkMode });
  }, []);

  const handleFormChange = (changedValues) => {
    setPreferences({ ...preferences, ...changedValues });
    setIsChanged(true);
  };

  const handleSave = () => {
    // Save preferences to localStorage
    localStorage.setItem('dataAggregationLevel', preferences.dataAggregationLevel);
    localStorage.setItem('darkMode', preferences.darkMode);
    setUserEnabledDarkMode(preferences.darkMode);
    notification.success({
      message: 'Preferences Saved',
      description: 'Your preferences have been saved successfully.',
    });
    setIsChanged(false);
  };

  // Handle selection from GooglePlacesAutocomplete
  const handleSelect = (value) => {
    // console.log("value: ", value);
    setAddress(value);
    form.setFieldsValue({ address: { label: value?.label, value: { description: value.value.description, place_id: value.value.placeId } } });
    if (value?.label) {
      getPlaceIdAndCoords(value.label);
    }
  };

  // Get place ID and coordinates based on the selected address
  const getPlaceIdAndCoords = (val) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address: val }, (results, status) => {
      if (status === 'OK' && results[0]) {
        // console.log("Results: ", results);
        const local_placeId = results[0].place_id;
        const location = results[0].geometry.location;

        setCustomAddress({ placeId: local_placeId, description: val, lat: location.lat(), long: location.lng() });
      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }
    });
  };


  // Payments
  // TODO: MOVE TO SEPERATE FILE
  const [isRechargeModalVisible, setIsRechargeModalVisible] = useState(false);
  const [rechargeAmount, setRechargeAmount] = useState(0);
  const [rechargeAmountCost, setRechargeAmountCost] = useState(0);


  const handlePaystackSuccessAction = async (reference) => {
    const token = localStorage.getItem('accessToken');
    // Optionally, update the user's credits in the state or fetch the updated data from the server
    // logger.log("Profile", 'Payment Reference:', reference);
    const credit_result = await confirmTransaction(token, reference);
    // logger.log("Profile", credit_result);
    if (credit_result.status === "200") {
      notification.success({
        message: 'Payment Successful',
        description: 'You have successfully recharged your credits.',
      });
      setPurchaseSuccess(true); // Set the purchase success state to true
      // businessData['business_credits'] += rechargeAmount;
      setIsRechargeModalVisible(false);
    } else {
      notification.error({
        message: 'Payment Failed',
        description: 'An error occurred while recharging your credits.',
      });
    }
  };

  const handlePaystackCloseAction = () => {
    // Implement what should happen when the Paystack modal is closed
    notification.info({
      message: 'Payment Cancelled',
      description: 'You have cancelled the payment process.',
    });
  };

  const handleBankTransferConfirmationPending = () => {
    notification.info({
      message: 'Bank Transfer Pending',
      description: 'Your bank transfer is pending confirmation. Please wait for the confirmation. This will close one confirmation is completed.',
    });
  };



  let paystackConfig = null;

  if (businessData && businessData.business_email) {
    paystackConfig = {
      reference: (new Date()).getTime().toString(),
      email: businessData.business_email,
      amount: rechargeAmountCost * 100, // Paystack works with kobo, so multiply by 100. a.25 is so its R1.25 per credit
      currency: 'ZAR', // Set the currency here (e.g., NGN, GHS, USD)
      publicKey: config.paystackPublicKey,
      metadata: {
        business_uuid: businessData.uuid, // Pass user UUID or any other metadata
        business_email: businessData.business_email,
        amount_of_credits: (rechargeAmount),
      },
    };
  }

  const showRechargeModal = () => {
    setIsRechargeModalVisible(true);
  };

  const handleRechargeCancel = () => {
    setIsRechargeModalVisible(false);
  };

  const handleRechargeAmountChange = (e) => {
    setRechargeAmount(e.target.value);
    setRechargeAmountCost(e.target.value*1.25);
    setSelectedPackage({ label: 'Custom', value: e.target.value, cost: e.target.value*1.25, duration: e.target.value * 2 * 5 + " days" });
  };

  // Define the packages for credit purchase
  const packages = [
    { label: 'Basic', value: 28, cost: 35, duration: '7 Days' },
    { label: 'Standard', value: 56, cost: 70, duration: '14 Days' },
    { label: 'Bulk', value: 120, cost: 150, duration: '30 Days' },
    { label: 'Custom', value: 'custom', cost: 28, duration: 'custom' },
  ];

  const [purchaseSuccess, setPurchaseSuccess] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState(null);

  const handlePackageSelect = (pkg) => {
    if (pkg.value === 'custom') {
      setRechargeAmount(28);
      setRechargeAmountCost(35);
    } else {      
      setRechargeAmount(pkg.value);
      setRechargeAmountCost(pkg.cost);
    }
    setSelectedPackage(pkg);
  };

  const [form] = Form.useForm();
  const [business_form] = Form.useForm();
  const [register_form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const accessToken = localStorage.getItem('accessToken');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [businessLoading, setBusinessLoading] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBusinessAdmin, setIsBusinessAdmin] = useState(false);

  const [messageText, setMessageText] = useState('');

  const [businessCredits, setBusinessCredits] = useState(0);

  useEffect(() => async () => {
    try {
      if (userData) {
        const token = localStorage.getItem('accessToken');
        const creds = await fetchCreditBalance(token);
        if(!userData.isSuperAdmin){
       
        setBusinessCredits(creds);
      }else {
        setBusinessCredits('N/A');
      }
        
        setLoading(false);
        setCustomAddress(userData.address);
        setAddress({ label: userData.address.description, value: { description: userData.address.description, place_id: userData.address.placeId } });
        setIsBusinessAdmin(userData.privilage.level === "1");
        if (!isBusinessAdmin && businessData) {
          setMessageText('You are not a business admin, you cannot edit business data');
        } else if (!businessData) {
          setMessageText('No linked businesses could be found. Please register a new business or get an invite from an existing business.');
        }
      }
    } catch (error) {
      console.error('Failed to fetch monitors:', error);
    }
  }, [userData]);


  useEffect(() => {
    // we fetch number of monitors for account
    const token = localStorage.getItem('accessToken');
    const fetchMons = async () => {
      try {
        const monitors = await fetchMonitors(token);
        setNumMonitors(monitors.length);
      } catch (error) {
        console.error('Failed to fetch monitors:', error);
      }
    };
    fetchMons();
  }, []);


  // const handleChange = (value) => {
    // console.log(`selected ${value}`);
  // }

  const onFinish = async (values) => {
    setButtonLoading(true);
    try {
      const updatedValues = {
        ...values,
        address: customAddress ? customAddress : values.address,
      };
      const response = await pushUserData(accessToken, updatedValues);
      // console.log(response);
      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'User profile updated successfully.',
        });
        const updatedUserData = {
          ...userData,
          ...values,
        };
        setUserData(updatedUserData);
      } else {
        notification.error({
          message: 'Error',
          description: 'Failed to update user profile.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while updating the profile.',
      });
    } finally {
      setButtonLoading(false);
    }
  };

  const [isRegisterButtonDisabled, setIsRegisterButtonDisabled] = useState(true);

  // Debounce the validation function to prevent excessive calls
  const debouncedCheckRegisterFormValidity = debounce(async () => {
    try {
      await register_form.validateFields();
      setIsRegisterButtonDisabled(false);
    } catch (error) {
      setIsRegisterButtonDisabled(true);
    }
  }, 300);

  const onFinishBusiness = async (values) => {
    setBusinessLoading(true);
    try {
      const response = await pushBusinessData(accessToken, values);
      // console.log(response);
      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Business profile updated successfully.',
        });
        const updatedBusinessData = {
          ...businessData,
          ...values,
        };
        setBusinessData(updatedBusinessData);
      } else {
        notification.error({
          message: 'Error',
          description: 'Failed to update business profile.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while updating the business.',
      });
    } finally {
      setBusinessLoading(false);
    }
    // console.log('Business:', values);
  };

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const [voucherCode, setVoucherCode] = useState("");

  const handleVoucher = async () => {
    // console.log(voucherCode);
    const token = localStorage.getItem('accessToken');
    try {
      const vouch = await claimVoucherCode(token, voucherCode);
      // console.log("Vouch", vouch);
      if (vouch.status === "200") {
        notification.success({
          message: 'Voucher Claimed',
          description: 'You have successfully claimed the voucher.',
        });
      } else {
        notification.error({
          message: 'Error',
          description: vouch.data,
        });
        setVoucherCode("");
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while claiming the voucher.',
      });
    }
  };

  const onFinishRegister = async (values) => {
    setRegisterLoading(true);
    try {
      const response = await registerBusiness(accessToken, values);
      // console.log(response);
      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Business registered successfully.',
        });
        // Optionally update state or perform other actions
        // const temp_data = {
        //   ...values,
        // }



      } else {
        notification.error({
          message: 'Error',
          description: 'Failed to register business.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while registering the business.',
      });
    } finally {
      setRegisterLoading(false);
      setIsModalVisible(false);

      await delay(2000); // Wait for 2 seconds
      window.location.reload(); // Reload the page after registering a new business
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleInvite = async () => {
    try {
        const token = localStorage.getItem('accessToken');
        const invite_response = await inviteUserToBusiness(token, { email });
        // logger.log("Home", "invite_response", invite_response);
        // Handle the response from the invitation
        if (invite_response.status === '200') {
            message.success(`Invitation sent to ${email}`);

        } else {
            message.error(`Failed to invite ${email}`);
        }
        // Reset the email input
        setEmail('');
    } catch (error) {
        console.error('Failed to invite user:', error);
        message.error('An error occurred while inviting the user');
    }
};

  const handleMonitorRegister = async () => {
    try {
        const token = localStorage.getItem('accessToken');
        const response_data = await registerMonitorToAccount(token, { newMonitorID });
        // console.log("response_data", response_data);
        if (response_data.status === '200') {
            // now add this monitor to the list of monitors
            // const newMonitorData = await fetchMonitors(token);
            // setLocalMonitors(newMonitorData);
            // setMonitorData(newMonitorData);
            // setHasNoMonitors(false);
            message.success(`Monitor ${newMonitorID} added successfully`);
        } else {
            message.error(`Failed to add monitor ${newMonitorID}. ` + response_data.message);
        }

    } catch (error) {
        console.error('Failed to fetch monitors:', error);
    }
};

  if (!isLoaded) return <div>
    <Spin fullscreen />
  </div>;


  return (
    
    <div>




      <h1 style={{ textAlign: 'left' }}>Settings</h1>
      <Tabs defaultActiveKey="1">
        <TabPane tab="My Details" key="1">
          <div>
            <Flex gap="large">
          <div style={{ maxWidth: '800px', margin: '0 auto' }}>
            <h3>Personal Details</h3>
            <p style={{ textAlign: 'left' }}>Save your personal profile details.</p>
            <Skeleton loading={loading} active>
              <Form
                form={form}
                name="userProfile"
                onFinish={onFinish}
                initialValues={{
                  email: userData ? userData.email : '',
                  fullname: userData ? userData.fullname : '',
                  // address: userData ? userData.address.description : '',
                  phone_number: userData ? userData.phone_number : '',
                }}
                style={{ maxWidth: '800px', margin: '0 auto' }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Full Name"
                      name="fullname"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Full Name!',
                        },
                      ]}
                    >
                      <Input placeholder="Enter your full name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Email!',
                        },
                      ]}
                    >
                      <Input disabled placeholder="Enter your email" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Phone Number"
                      name="phone_number"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Phone Number!',
                        },
                      ]}
                    >
                      <Input placeholder="Enter your phone number" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Address"
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Address!',
                        },
                      ]}
                    >
                      <GooglePlacesAutocomplete
                        apiKey={GOOGLE_MAPS_API_KEY}
                        selectProps={{
                          value: address,
                          onChange: handleSelect,
                          placeholder: 'Enter your address',
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={buttonLoading}>
                    SAVE
                  </Button>
                </Form.Item>
              </Form>
            </Skeleton>
            
          </div>
          
          <div style={{ maxWidth: '800px', minWidth:'600px', margin: '0 auto' }}>
      <h3>Preferences</h3>
      <p style={{ textAlign: 'left' }}>Adjust your preferences for this device</p>
      <Skeleton loading={loading} active>
        <Form
          initialValues={preferences}
          onValuesChange={handleFormChange}
          onFinish={handleSave}
        >
          <Form.Item
            label="Data aggregation level"
            name="dataAggregationLevel"
            tooltip="Select the level of detail for data aggregation"
          >
            <Select>
              <Option value="minutely">Minutely - Default Accuracy</Option>
              <Option value="hourly">Hourly - Improved Performance. Similar Accuracy</Option>
              
            </Select>
          </Form.Item>

          <Form.Item
            label="Night mode / Dark mode"
            name="darkMode"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          {/* <DebugItem variable={isChanged} name={"isChanged"} /> */}
{isChanged && (
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Preferences
            </Button>
          </Form.Item>
        )}
        </Form>
      </Skeleton>
    
    </div>
    
</Flex>
<FeedbackCard />
          </div>
        </TabPane>
        <TabPane tab="Business Management" key="2">
          {businessData && isBusinessAdmin ? (
            <div>
              <Flex gap="large">
            <div style={{ maxWidth: '800px', margin: '0 auto' }}>
              <Skeleton loading={loading} active>
                <Row>
                  <Col span={24}>
                    <h3>Business Details</h3>
                    <p style={{ textAlign: 'left' }}>More details about your business.</p>
                  </Col>
                </Row>
                <Form
                  form={business_form}
                  name="businessProfile"
                  onFinish={onFinishBusiness}
                  initialValues={{
                    business_email: businessData ? businessData.business_email : '',
                    business_name: businessData ? businessData.business_name : '',
                    business_phone_number: businessData ? businessData.business_phone_number : '',
                    business_markup: businessData ? businessData.business_markup : '',
                    business_vat: businessData ? businessData.business_vat : '',
                    business_description: businessData ? businessData.business_description : '',
                    business_website: businessData ? businessData.business_website : '',
                    business_warehouse: businessData ? businessData.business_warehouse : '',
                  }}
                  style={{ maxWidth: '800px', margin: '0 auto' }}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Business Phone Number"
                        name="business_phone_number"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Phone Number!',
                          },
                        ]}
                      >
                        <Input placeholder="Enter your business phone number" type="tel" maxLength={15} disabled={!isBusinessAdmin} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Business Email"
                        name="business_email"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Email!',
                          },
                        ]}
                      >
                        <Input placeholder="Enter your business email" disabled={!isBusinessAdmin} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Business Name"
                        name="business_name"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Business Name',
                          },
                        ]}
                      >
                        <Input placeholder="Enter your business name" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Website"
                        name="business_website"
                        rules={[
                          {
                            required: false,
                            message: 'Please input your Business Website',
                          },
                        ]}
                      >
                        <Input placeholder="Enter your business website" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    label="Business Description"
                    name="business_description"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Business Description',
                      },
                    ]}
                  >
                    <Input.TextArea placeholder="Enter a description of your business" rows={4} maxLength={200} />
                  </Form.Item>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Vat Number"
                        name="business_vat"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your VAT Number!',
                          },
                        ]}
                      >
                        <Input placeholder="Enter your VAT number" type="number" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Markup"
                        name="business_markup"
                        rules={[
                          {
                            required: false,
                            message: 'Please input your Markup!',
                          },
                        ]}
                      >
                        <Input placeholder="Enter your markup" type="number" />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Form.Item label="Warehouse" name="business_warehouse">
                    <Select defaultValue="Cape Town" style={{ width: 120 }} onChange={handleChange}>
                      
                    </Select>
                  </Form.Item> */}
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={businessLoading}>
                      SAVE
                    </Button>
                  </Form.Item>
                </Form>
              </Skeleton>


            </div>
            <div style={{ maxWidth: '800px', margin: '0 auto' }}>
                    <h3>User Management</h3>
                    <div>
                        <p>Enter email below to invite that user to your business. This will allow them to view the business monitors, ect...</p>
                        <Input
                            type="email"
                            placeholder="Enter user's email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ width: '300px', marginRight: '10px' }}
                        />
                        <Button type="primary" onClick={handleInvite}>
                            Invite
                        </Button>
                    </div>
                    <div>
                        <h3>Monitor Management</h3>
                        <p>As a business Admin, you can add monitors to your business</p>
                        <Input placeholder="Enter the Monitor ID" style={{ width: '300px', marginRight: '10px' }} value={newMonitorID}
                            onChange={(e) => setNewMonitorID(e.target.value)} />
                        <Button type="primary" onClick={handleMonitorRegister}>
                            Add Monitor
                        </Button>
                    </div>
                </div>
                </Flex>
            </div>

          ) : (

            <p style={{ maxWidth: '800px', margin: '0 auto' }}>{messageText}</p>

          )}

          {/* IF NOT A BUSINES YET */}
          {!businessData && (
            <div style={{ maxWidth: '800px', margin: '0 auto' }}>

              <Row>
                <Col span={24}>
                  <Button type="primary" onClick={showModal}>
                    Register a New Business
                  </Button>
                </Col>
              </Row>
              <Modal
                title="Register a New Business"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={800}
              >
                <Form
                  form={register_form}
                  name="registerBusiness"
                  onFinish={onFinishRegister}
                  onFieldsChange={debouncedCheckRegisterFormValidity}
                  style={{ maxWidth: '600px', margin: '0 auto' }}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Business Name"
                        name="business_name"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Business Name',
                          },
                        ]}
                      >
                        <Input placeholder="Enter your business name" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Business Email"
                        name="business_email"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Business Email',
                          },
                        ]}
                      >
                        <Input placeholder="Enter your business email" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Business Phone Number"
                        name="business_phone_number"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Business Phone Number',
                          },
                        ]}
                      >
                        <Input placeholder="Enter your business phone number" type="tel" maxLength={15} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Website"
                        name="business_website"
                        rules={[
                          {
                            required: false,
                            message: 'Please input your Business Website',
                          },
                        ]}
                      >
                        <Input placeholder="Enter your business website" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    label="Business Description"
                    name="business_description"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Business Description',
                      },
                    ]}
                  >
                    <Input.TextArea placeholder="Enter a description of your business" rows={4} maxLength={200} />
                  </Form.Item>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Vat Number"
                        name="business_vat"
                        rules={[
                          {
                            required: false,
                            message: 'Please input your VAT Number!',
                          },
                        ]}
                      >
                        <Input placeholder="Enter your VAT number" type="number" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Markup"
                        name="business_markup"
                        rules={[
                          {
                            required: false,
                            message: 'Please input your Markup!',
                          },
                        ]}
                      >
                        <Input placeholder="Enter your markup" type="number" />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Form.Item label="Warehouse" name="warehouse">
                    <Select defaultValue="Cape Town" style={{ width: 120 }} onChange={handleChange}>
                 
                    </Select>
                  </Form.Item> */}
                  <Form.Item
                    name="tos"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject(new Error('You must accept the terms and conditions')),
                      },
                    ]}
                  >
                    <Checkbox>
                      I have read and agree to the <a href="/terms">Terms and Conditions</a>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={registerLoading} disabled={isRegisterButtonDisabled}>
                      Register New Business
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          )}
        </TabPane>


        <TabPane tab="Credit Management" key="3">
          {purchaseSuccess ? (
            <div style={{ width: '100%' }}>
              <Result
                status="success"
                title="Successfully Purchased Credits!"

                subTitle={`You have successfully added ${rechargeAmount} credits to your account.`}
                extra={[
                  <Button type="primary" key="console" onClick={() => window.location.reload()}>
                    Go Back
                  </Button>
                ]}
              />
            </div>
          ) : (
            <>


              {businessData && isBusinessAdmin ? (
                <div style={{ maxWidth: '800px', margin: '0 auto' }}>
                  {userData.isSuperAdmin && (
                  <Alert message="You are a super admin, and cannot recharge credits as you have infinite credits" type="warning" showIcon />
                )}
                  <Flex gap="large">
                  <div>
                  <h2>Credits</h2>
                
                  <Col>
                    <Flex gap='large'>
                      {userData.isSuperAdmin ? (<Statistic title="Account Balance (Credits)" value="∞" />) : (
                        <Statistic title="Account Balance (Credits)" value={businessCredits} precision={2} />
                      )}
                    
                      <Statistic title="Account Balance (Credit Hours)" value={businessCredits * 6} precision={2} />
                    </Flex>
                    <Button type="primary" style={{ marginTop: 16 }} onClick={showRechargeModal}>
                      Recharge Credits
                    </Button>
                  </Col>
                  </div>

                  <div>
                   <h2>Claim Voucher</h2>
                   <p>Claim a voucher code to add credits to your account</p>
                   <Input
        placeholder="Enter your voucher code"
        value={voucherCode}
        onChange={(e) => setVoucherCode(e.target.value)}
      />
      <Button type="primary" style={{ marginTop: 16 }} onClick={handleVoucher}>
        Claim Voucher
      </Button>

                  </div>
                  </Flex>

                  

                  <Modal
                    title="Recharge Credits"
                    open={isRechargeModalVisible}
                    onCancel={handleRechargeCancel}
                    footer={null}
                    width={800}
                  >
                    <Form>
                      <FormItem>
                        Select a package to recharge your credits for your business. All payments are done securly via Paystack. Please note that the more monitors you have, the less time the credits will last for.
                      </FormItem>
                      <Row gutter={16}>
                        {packages.map((pkg) => (
                          <Col span={6} key={pkg.label}>
                            <Card

                              hoverable
                              onClick={() => handlePackageSelect(pkg)}
                              style={{
                                borderColor: selectedPackage?.label === pkg.label ? '#1890ff' : '#f0f0f0',
                                backgroundColor: selectedPackage?.label === pkg.label ? '#e6f7ff' : '#fff',
                                height: '230px'
                              }}
                            >
                              <h3>{pkg.label}</h3>
                              {pkg.value !== 'custom' && <p>{pkg.value} credits</p>}
                              {pkg.value !== 'custom' && <p>{((pkg.value * 6) / 24)/numMonitors} Days</p>}


                              {pkg.value !== 'custom' && <p>{pkg.cost} ZAR</p>}

                              {pkg.value === 'custom' && (
                                <span>
                                  <Input
                                    type="number"
                                    placeholder="Enter your desired credit amount"
                                    value={rechargeAmount}
                                    onChange={handleRechargeAmountChange}
                                    min={5}

                                  />
                                  {pkg.value === 'custom' && <p>{(rechargeAmount * 6) / 24} Days</p>}
                                  <p>{rechargeAmountCost} ZAR</p>
                                </span>
                              )}
                            </Card>
                          </Col>
                        ))}
                      </Row>
                      <Form.Item>
                        <h3>Summary Breakdown:</h3>
                        <p>You selected the {selectedPackage?.label} package, which provides {rechargeAmount} additional credits to your business. Usage Estimate is around ~{(rechargeAmount * 6) / 24 + " Days"} of usage for your {numMonitors} monitors(s). These will not expire.</p>
                        <p>
                          <strong>{rechargeAmount} credits</strong> will be added to your account.
                        </p>
                        <p>
                          This will cost <strong>R{rechargeAmountCost}</strong>
                        </p>
                      </Form.Item>
                      <Form.Item>
                        <PaystackButton
                          {...paystackConfig}
                          text="Pay Now"
                          onSuccess={handlePaystackSuccessAction}
                          onClose={handlePaystackCloseAction}
                          onBankTransferConfirmationPending={handleBankTransferConfirmationPending}
                          disabled={rechargeAmount < 28}
                          className="paystack-btn paystack-btn-primary"
                        />
                      </Form.Item>
                    </Form>
                  </Modal>
                </div>
              ) : (
                <p style={{ maxWidth: '800px', margin: '0 auto' }}>{messageText}</p>
              )}


            </>
          )}
        </TabPane>


      </Tabs>


    </div>
   

  );
}

Profile.propTypes = {
  businessData: PropTypes.object,
  userData: PropTypes.object,
  setBusinessData: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  setUserEnabledDarkMode: PropTypes.func.isRequired,
};

export default Profile;