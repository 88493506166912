import dayjs from 'dayjs';
import logger from '../utils/logger';
logger.enableLogging('Calculations');

function roundTo(value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

/**
 * Converts raw hourly readings to the desired format.
 * 
 * @param {*} rawDataArray 
 * @returns 
 */
export const formatHourlyRawData = (rawDataArray, useMaxForPeak = false) => {

  

    const formatted_data = {
        clamp1watt: [],
        clamp2watt: [],
        clamp3watt: []
    };

    rawDataArray.forEach(rawData => {
        const time = dayjs(rawData.clamp1v.time).tz('Etc/GMT'); //rawData.clamp1v.time; // Assuming all times are the same for a given hour

        
        // const clamp1watt = rawData.clamp1v.value * rawData.clamp1i.value;
        // const clamp2watt = rawData.clamp2v.value * rawData.clamp2i.value;
        // const clamp3watt = rawData.clamp3v.value * rawData.clamp3i.value;

        //  made change so if we are using data for peaks, it uses the max not the avg

        const clamp1watt = useMaxForPeak 
        ? rawData.clamp1v.max * rawData.clamp1i.max 
        : rawData.clamp1v.value * rawData.clamp1i.value;

        const clamp2watt = useMaxForPeak 
        ? rawData.clamp2v.max * rawData.clamp2i.max 
        : rawData.clamp2v.value * rawData.clamp2i.value;

        const clamp3watt = useMaxForPeak 
        ? rawData.clamp3v.max * rawData.clamp3i.max 
        : rawData.clamp3v.value * rawData.clamp3i.value;

        formatted_data.clamp1watt.push({ time, max: clamp1watt });
        formatted_data.clamp2watt.push({ time, max: clamp2watt });
        formatted_data.clamp3watt.push({ time, max: clamp3watt });
    });

   

    return formatted_data;
};


/**
 * Converts raw hourly readings to the desired format by combining wattages of all clamps.
 * 
 * @param {*} rawDataArray 
 * @returns 
 */
export const formatHourlyRawDataCombined = (rawDataArray) => {

    const combined_data = [];

    rawDataArray.forEach(rawData => {
        const time = rawData.clamp1v.time; // Assuming all times are the same for a given hour



        const clamp1watt_mean = rawData.clamp1v.value * rawData.clamp1i.value;
        const clamp2watt_mean = rawData.clamp2v.value * rawData.clamp2i.value;
        const clamp3watt_mean = rawData.clamp3v.value * rawData.clamp3i.value;

        const clamp1watt_max = rawData.clamp1v.max * rawData.clamp1i.max 
        const clamp2watt_max = rawData.clamp2v.max * rawData.clamp2i.max 
        const clamp3watt_max = rawData.clamp3v.max * rawData.clamp3i.max 
      

        const totalWatt = clamp1watt_mean + clamp2watt_mean + clamp3watt_mean;
        const maximum = clamp1watt_max + clamp2watt_max + clamp3watt_max;

        combined_data.push({ time, max: totalWatt, maximum: maximum });
    });

    return combined_data;
};

// Function to aggregate hourly readings into daily kWh
export const aggregateDailyKwh = (hourReadings, threephase) => {
    const dailyKwhAggregation = hourReadings.reduce((acc, reading) => {
        const date = dayjs(reading.clamp1v.time).tz('Etc/GMT').format('YYYY-MM-DD');
       
        if (!acc[date]) {
            acc[date] = threephase ? { kWh: 0 } : { clamp1kWh: 0, clamp2kWh: 0, clamp3kWh: 0 };
        }
        if (threephase) {
            acc[date].kWh += (reading.clamp1i.value * reading.clamp1v.value) / 1000; // Convert to kWh
            acc[date].kWh += (reading.clamp2i.value * reading.clamp2v.value) / 1000; // Convert to kWh
            if (reading.clamp3i && reading.clamp3v) {
                acc[date].kWh += (reading.clamp3i.value * reading.clamp3v.value) / 1000; // Convert to kWh
            }
        } else {
            acc[date].clamp1kWh += (reading.clamp1i.value * reading.clamp1v.value) / 1000; // Convert to kWh
            acc[date].clamp2kWh += (reading.clamp2i.value * reading.clamp2v.value) / 1000; // Convert to kWh
            if (reading.clamp3i && reading.clamp3v) {
                acc[date].clamp3kWh += (reading.clamp3i.value * reading.clamp3v.value) / 1000; // Convert to kWh
            }
        }
        return acc;
    }, {});

    return Object.keys(dailyKwhAggregation).map(date => {
        if (threephase) {
            return {
                time: date,
                kWh: roundTo(dailyKwhAggregation[date].kWh, 2)
            };
        } else {
            return {
                time: date,
                clamp1kWh: roundTo(dailyKwhAggregation[date].clamp1kWh, 2),
                clamp2kWh: roundTo(dailyKwhAggregation[date].clamp2kWh, 2),
                clamp3kWh: roundTo(dailyKwhAggregation[date].clamp3kWh, 2)
            };
        }
    });
};



/**
 * Aggregates daily data from formatted hourly watt values.
 * 
 * @param {Object} formattedHourlyData - The formatted hourly watt values.
 * @returns {Object} - The aggregated daily data.
 */
export const aggregateDailyData = (formattedHourlyData) => {

 
    const dailyData = {
        clamp1watt: {},
        clamp2watt: {},
        clamp3watt: {}
    };

    const aggregateFieldData = (fieldData) => {
        const dailyAggregation = {};

        fieldData.forEach(item => {
            const date = dayjs(item.time).tz('Etc/GMT').format('YYYY-MM-DD');
            if (!dailyAggregation[date]) {
                dailyAggregation[date] = { totalWatt: 0, maxWatt: 0 };
            }
            dailyAggregation[date].totalWatt += item.max;
            dailyAggregation[date].maxWatt = Math.max(dailyAggregation[date].maxWatt, item.max);
        });

        return dailyAggregation;
    };

    dailyData.clamp1watt = aggregateFieldData(formattedHourlyData.clamp1watt);
    dailyData.clamp2watt = aggregateFieldData(formattedHourlyData.clamp2watt);
    dailyData.clamp3watt = aggregateFieldData(formattedHourlyData.clamp3watt);

    // logger.log('Calculations', "Aggregated daily data", dailyData);

    return dailyData;
};



/**
 * Aggregates clamp watt data for a specific day, either hourly or by minute.
 * 
 * @param {Array} clampWattData - An array of objects representing the clamp watt data. Each object should have a `time` property (ISO 8601 string) and a `value` property (number representing the watt value).
 * @param {String} currentDay - A string representing the day to aggregate data for, in `YYYY-MM-DD` format.
 * @param {String} format - A string indicating the aggregation format. It can be either 'hourly' or 'minute'.
 * @returns {Object} An object where keys are time intervals (either hourly or by minute) and values are objects containing:
 *   - `time` {String}: The time interval.
 *   - `totalWatt` {Number}: The total watt value for the time interval.
 *   - `count` {Number}: The number of records aggregated for the time interval.
 */
const aggregateData = (clampWattData, currentDay, format) => {
    if (!clampWattData) return {};

    const aggregatedData = clampWattData
        .filter(record => {
            const recordTime = dayjs(record.time);
            return recordTime.isSame(dayjs(currentDay), 'day');
        })
        .reduce((acc, record) => {
            const timeKey = format === 'hourly' ? dayjs(record.time).format('YYYY-MM-DD HH') : dayjs(record.time).format('YYYY-MM-DD HH:mm');
            if (!acc[timeKey]) {
                acc[timeKey] = {
                    time: timeKey,
                    totalWatt: 0,
                    count: 0
                };
            }
            acc[timeKey].totalWatt += record.value; // Assuming 'value' is the watt value
            acc[timeKey].count += 1;
            return acc;
        }, {});

    if (format === 'hourly') {
        // Convert total watt to kWh by multiplying by 1/60
        Object.keys(aggregatedData).forEach(timeKey => {
            aggregatedData[timeKey].totalWatt = aggregatedData[timeKey].totalWatt * (1 / 60);
        });
    }

    return aggregatedData;
};


/**
 * Generates data for a daily kWh graph.
 *
 * @param {Object[]} raw_hourly_readings - The calculated daily kWh data.
 * @param {Date} startDate - The start date for the data range.
 * @param {Date} endDate - The end date for the data range.
 * @param {boolean} isClamp1 - Flag to include clamp 1 data.
 * @param {boolean} isClamp2 - Flag to include clamp 2 data.
 * @param {boolean} isClamp3 - Flag to include clamp 3 data.
 * @param {boolean} isThreePhase - Flag to indicate if the system is three-phase.
 * @returns {Object[]} - The data for the daily kWh graph.
 */
export const dailyKWHGraph = (raw_hourly_readings, startDate, endDate, isClamp1, isClamp2, isClamp3, isThreePhase) => {
    const newData = [];

    const dailyData = aggregateDailyKwh(raw_hourly_readings, isThreePhase);

    // console.log("DEBUG - dailyKWHGraph - dailyData", dailyData);

    if (dailyData) {
        const filteredData = dailyData.filter(record => {
            const recordTime = dayjs(record.time).tz('Etc/GMT').add(3, 'hour');
            
            const start = dayjs(startDate).tz('Etc/GMT');
            const end = dayjs(endDate).tz('Etc/GMT');

            // console.log("start ", start.format(), " end ", end.format(), " recordTime ", recordTime.format());
          
            // console.log("DEBUG - dailyKWHGraph - recordTime", recordTime.format());
           
            // console.log("Start ", start.format(), " End ", end.format());
          
            return recordTime.isBetween(start, end, null, '[]');
        });

        // console.log("DEBUG - dailyKWHGraph - filteredData", filteredData);

        if (isThreePhase) {
            // Combine data from all clamps
            const combinedData = filteredData.map(record => ({
                time: record.time,
                kWh: roundTo(record.kWh, 2)
            }));

            const x = combinedData.map(record => record.time);
            const y = combinedData.map(record => record.kWh);

            newData.push({
                x: x,
                y: y,
                type: 'bar',
                marker: { color: '#1F77B4' },
                hoverinfo: 'x+y',
                name: 'Combined Clamps kWh',
                text: y,
                textposition: 'inside',
                insidetextanchor: 'middle',
            });
        } else {
            if (isClamp1) {
                const clamp1Data = filteredData.map(record => ({
                    time: dayjs(record.time).tz('Etc/GMT').add(1, 'day').format('YYYY-MM-DD'),
                    kWh: roundTo(record.clamp1kWh, 2)
                }));
                // console.log("TIME ", clamp1Data);

                const x = clamp1Data.map(record => record.time);
                const y = clamp1Data.map(record => record.kWh);

                newData.push({
                    x: x,
                    y: y,
                    type: 'bar',
                    marker: { color: '#1F77B4' },
                    hoverinfo: 'x+y',
                    name: 'Clamp 1 kWh',
                    text: y,
                    textposition: 'inside',
                    insidetextanchor: 'middle',
                    textFont: { family: 'Arial', size: 18}
                });
            }

            if (isClamp2) {
                const clamp2Data = filteredData.map(record => ({
                    time: dayjs(record.time).tz('Etc/GMT').add(1, 'day').format('YYYY-MM-DD'),
                    kWh: roundTo(record.clamp2kWh, 2)
                }));

                const x = clamp2Data.map(record => record.time);
                const y = clamp2Data.map(record => record.kWh);

                newData.push({
                    x: x,
                    y: y,
                    type: 'bar',
                    marker: { color: '#FF7F0E' },
                    hoverinfo: 'x+y',
                    name: 'Clamp 2 kWh',
                    text: y,
                    textposition: 'inside',
                    insidetextanchor: 'middle',
                    textFont: { family: 'Arial', size: 18}
                    
                });
            }

            if (isClamp3) {
                const clamp3Data = filteredData.map(record => ({
                    time: dayjs(record.time).tz('Etc/GMT').add(1, 'day').format('YYYY-MM-DD'),
                    kWh: roundTo(record.clamp3kWh, 2)
                }));

                const x = clamp3Data.map(record => record.time);
                const y = clamp3Data.map(record => record.kWh);

                newData.push({
                    x: x,
                    y: y,
                    type: 'bar',
                    marker: { color: '#2CA02C' },
                    hoverinfo: 'x+y',
                    name: 'Clamp 3 kWh',
                    text: y,
                    textposition: 'inside',
                    insidetextanchor: 'middle',
                    textFont: { family: 'Arial', size: 18}
                    
                });
            }
        }
    }

    // console.log("DEBUG - dailyKWHGraph - newData", newData);

    return newData;
    
};





/**
 * Generates data for an hourly kW graph between ranged dates.
 *
 * @param {Object[]} raw_hourly_readings - The raw hourly readings
 * @param {Date} startDate - The start date for the data range.
 * @param {Date} endDate - The end date for the data range.
 * @param {boolean} isClamp1 - Flag to include clamp 1 data.
 * @param {boolean} isClamp2 - Flag to include clamp 2 data.
 * @param {boolean} isClamp3 - Flag to include clamp 3 data.
 * @param {boolean} isThreePhase - Flag to indicate if the system is three-phase.
 * @returns {Object[]} - The data for the hourly kW graph.
 */
export const CalculateHourlyKwGraph = (raw_hourly_readings, startDate, endDate, isClamp1, isClamp2, isClamp3, isThreePhase) => {
    const hourlyKwData = [];

    // Safeguard to limit the date range to a maximum of 14 days
    const maxDays = 14;
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const diffDays = end.diff(start, 'day');

    if (diffDays > maxDays) {
        endDate = start.add(maxDays, 'day').toDate();
    }
    
    // logger.log('Calculations', "Passed raw hours", raw_hourly_readings);

    const filteredData = raw_hourly_readings.filter(record => {
        const recordTime = dayjs(record.clamp1v.time);
        return recordTime.isSame(dayjs(startDate), 'hour') ||
            recordTime.isSame(dayjs(endDate), 'hour') ||
            recordTime.isBetween(dayjs(startDate), dayjs(endDate), 'hour', '[]');
    });

    // logger.log('Calculations', "filteredData hourly", filteredData);

    const calculatePower = (v, i) => (v * i) / 1000;

    if (isThreePhase) {
        // Combine data from all clamps
        const combinedData = filteredData.map(record => ({
            time: record.clamp1v.time,
            kW: roundTo(
                calculatePower(record.clamp1v.value, record.clamp1i.value) +
                calculatePower(record.clamp2v.value, record.clamp2i.value) +
                calculatePower(record.clamp3v.value, record.clamp3i.value),
                2
            )
        }));

        const x = combinedData.map(record => record.time);
        const y = combinedData.map(record => record.kW);
        const text = combinedData.map(record => dayjs(record.time).format('HH:mm DD MMM'));

        hourlyKwData.push({
            x: x,
            y: y,
            type: 'line',
            marker: { color: '#1F77B4' },
            hovertemplate: '%{y} kW<br>%{text}<extra></extra>',
            name: 'Clamps kW',
            text: text,
            textposition: 'inside',
            insidetextanchor: 'middle',
        });
    } else {
        if (isClamp1) {
            const clamp1Data = filteredData.map(record => ({
                time: record.clamp1v.time,
                kW: roundTo(calculatePower(record.clamp1v.value, record.clamp1i.value), 2)
            }));

            const x = clamp1Data.map(record => record.time);
            const y = clamp1Data.map(record => record.kW);
            const text = clamp1Data.map(record => dayjs(record.time).format('HH:mm DD MMM'));

            hourlyKwData.push({
                x: x,
                y: y,
                type: 'line',
                marker: { color: '#1F77B4' },
                hovertemplate: '%{y} kW<br>%{text}<extra></extra>',
                name: 'Clamp 1 kW',
                text: text,
                textposition: 'inside',
                insidetextanchor: 'middle',
            });
        }

        if (isClamp2) {
            const clamp2Data = filteredData.map(record => ({
                time: record.clamp2v.time,
                kW: roundTo(calculatePower(record.clamp2v.value, record.clamp2i.value), 2)
            }));

            const x = clamp2Data.map(record => record.time);
            const y = clamp2Data.map(record => record.kW);
            const text = clamp2Data.map(record => dayjs(record.time).format('HH:mm DD MMM'));

            hourlyKwData.push({
                x: x,
                y: y,
                type: 'line',
                marker: { color: '#FF7F0E' },
                hovertemplate: '%{y} kW<br>%{text}<extra></extra>',
                name: 'Clamp 2 kW',
                text: text,
                textposition: 'inside',
                insidetextanchor: 'middle',
            });
        }

        if (isClamp3) {
            const clamp3Data = filteredData.map(record => ({
                time: record.clamp3v.time,
                kW: roundTo(calculatePower(record.clamp3v.value, record.clamp3i.value), 2)
            }));

            const x = clamp3Data.map(record => record.time);
            const y = clamp3Data.map(record => record.kW);
            const text = clamp3Data.map(record => dayjs(record.time).format('HH:mm DD MMM'));

            hourlyKwData.push({
                x: x,
                y: y,
                type: 'line',
                marker: { color: '#2CA02C' },
                hovertemplate: '%{y} kW<br>%{text}<extra></extra>',
                name: 'Clamp 3 kW',
                text: text,
                textposition: 'inside',
                insidetextanchor: 'middle',
            });
        }
    }

    return hourlyKwData;
};


function sumValues(dates, resss, isClamp1, isClamp2, isClamp3, isThreePhase) {
    const finalAmounts = [];

    // console.log("DEBUG - sumValues - resss", resss);

    

    dates.forEach(date => {
        let maxClamp1 = 0, maxClamp2 = 0, maxClamp3 = 0;

        if (isClamp1 && resss.clamp1watt[date]) {
            maxClamp1 = resss.clamp1watt[date].maxWatt;
        }
        if (isClamp2 && resss.clamp2watt[date]) {
            maxClamp2 = resss.clamp2watt[date].maxWatt;
        }
        if (isClamp3 && resss.clamp3watt[date]) {
            maxClamp3 = resss.clamp3watt[date].maxWatt;
        }

        console.log("max clamps for day:", date, maxClamp1, maxClamp2, maxClamp3);

        const maxValue = !isThreePhase ? maxClamp1 + maxClamp2 + maxClamp3 : Math.max(maxClamp1, maxClamp2, maxClamp3);
        finalAmounts.push(roundTo(maxValue > 0 ? maxValue / 1000 : 0, 1)); // Convert to kW
    });

    return finalAmounts;
}

export const calculatePeakData = (formatted_hourly_data, isClamp1, isClamp2, isClamp3, startDate, endDate, isThreePhase) => {
    const newPeakData = [];

    const dailyDataAggregation =  formatted_hourly_data; //formatHourlyRawData(newRawHourlyReadings);
    console.log("formatted hourly", formatted_hourly_data);
    const resss = aggregateDailyData(dailyDataAggregation);


    console.log("RESS", resss);


    if (resss) {
        const dates = Object.keys(resss.clamp1watt).filter(date => {
            
            const recordTime = dayjs(date).format('YYYY-MM-DD');
            const start = dayjs(startDate).tz('Etc/GMT').format('YYYY-MM-DD');
            const end = dayjs(endDate).tz('Etc/GMT').format('YYYY-MM-DD');
          
            // const isBetween = dayjs(recordTime).isBetween(start, end, null, '[]');
            const isBetween = dayjs(recordTime).isSame(dayjs(start)) || dayjs(recordTime).isSame(dayjs(end)) || dayjs(recordTime).isBetween(start, end, null, '[]');
            return isBetween;
        });

        // logger.log('Calculations', "PEAK DATES", dates);



        newPeakData.push({
            x: dates,
            y: sumValues(dates, resss, isClamp1, isClamp2, isClamp3, isThreePhase),
            type: 'bar',
            marker: { color: '#1F77B4' },
            hoverinfo: 'x+y',
            name: 'kWh',
            text: sumValues(dates, resss, isClamp1, isClamp2, isClamp3, isThreePhase),
            textposition: 'inside',
            insidetextanchor: 'middle',
            borderRadius: 5 // Add rounded corners
        });
    }

    return newPeakData;
};

function sumValuesForPeriod(clampData) {
    const dayConsumptionMap = {};
    const nightConsumptionMap = {};
    const numberDayHoursMap = {}; // Use an object for better date-based indexing
    const numberNightHoursMap = {};

    let currentDate;
    let hour;

    // Process each record
    clampData.forEach(record => {
        const recordTime = dayjs(record.time).tz('Etc/GMT');

        // Update the current date and hour for the new record
        currentDate = recordTime.format('YYYY-MM-DD');
        hour = recordTime.hour();

        // Initialize maps for the date if not already done
        if (!dayConsumptionMap[currentDate]) {
            dayConsumptionMap[currentDate] = 0;
            numberDayHoursMap[currentDate] = 0;
            
        }
        if(!nightConsumptionMap[currentDate]){
            nightConsumptionMap[currentDate] = 0;
            numberNightHoursMap[currentDate] = 0;
        }

        // Update consumption based on the hour
        if (hour > 7 && hour < 17) {
            // console.log("DEBUG - sumValuesForPeriod - DAY", currentDate, record.max);
            dayConsumptionMap[currentDate] += roundTo(record.max,0)/1000;
            numberDayHoursMap[currentDate] = (numberDayHoursMap[currentDate] || 0) + 1; // Increment hours count
        } else {
            nightConsumptionMap[currentDate] += roundTo(record.max,0)/1000;
            numberNightHoursMap[currentDate] = (numberNightHoursMap[currentDate] || 0) + 1; // Increment hours count
        }
    });


    // Sort dates for output
    const sortedDates = Object.keys(dayConsumptionMap).sort();

    // Convert to kWh and prepare output
    const dayConsumptionKWh = sortedDates.map(date => roundTo(dayConsumptionMap[date],1)); // Convert to kWh
    const nightConsumptionKWh = sortedDates.map(date => roundTo(nightConsumptionMap[date], 1)); // Convert to kWh

    return { sortedDates, dayConsumptionKWh, nightConsumptionKWh };
}



export const calculateDayNightSplit = (formatted_hourly_clamps, isClamp1, isClamp2, isClamp3, startDate, endDate, isThreePhase) => {
    const newBarChartData = [];

    // Convert raw data to the desired format
    // const formatted_hourly_clamps = formatHourlyRawData(newRawHourlyReadings);

    if (formatted_hourly_clamps) {
     

        const clamp1wattData = formatted_hourly_clamps['clamp1watt'] || [];
        const clamp2wattData = formatted_hourly_clamps['clamp2watt'] || [];
        const clamp3wattData = formatted_hourly_clamps['clamp3watt'] || [];

        const filterDataForPeriod = (data, startDate, endDate) => {
            return data.filter(record => {
                const recordTime = dayjs(record.time).tz('Etc/GMT').format();
                const isBetween = dayjs(recordTime).isBetween(dayjs(startDate).startOf('day'), dayjs(endDate).endOf('day'), null, '[]');
              
                return isBetween;
            });
        };

        const filteredClamp1Data = filterDataForPeriod(clamp1wattData, startDate, endDate);
        const filteredClamp2Data = filterDataForPeriod(clamp2wattData, startDate, endDate);
        const filteredClamp3Data = filterDataForPeriod(clamp3wattData, startDate, endDate);

        let combinedData = [];
        if (isThreePhase) {
            // combinedData = filteredClamp1Data;
            combinedData = [...filteredClamp1Data, ...filteredClamp2Data, ...filteredClamp3Data];
            
        } else {
            combinedData = [...filteredClamp1Data, ...filteredClamp2Data, ...filteredClamp3Data];
        }

       

        const { sortedDates, dayConsumptionKWh, nightConsumptionKWh } = sumValuesForPeriod(combinedData);

        newBarChartData.push(
            {
                x: sortedDates,
                y: dayConsumptionKWh,
                type: 'bar',
                name: 'Day Consumption',
                marker: { color: 'orange' },
                hoverinfo: 'x+y',
                text: dayConsumptionKWh,
                textposition: 'inside',
                insidetextanchor: 'middle',
                textangle: 0, // Force text to be displayed horizontally flat
                borderRadius: 5 // Add rounded corners
            },
            {
                x: sortedDates,
                y: nightConsumptionKWh,
                type: 'bar',
                name: 'Night Consumption',
                marker: { color: '#1F77B4' },
                hoverinfo: 'x+y',
                text: nightConsumptionKWh,
                textposition: 'inside',
                insidetextanchor: 'middle',
                textangle: 0, // Force text to be displayed horizontally flat
                borderRadius: 5 // Add rounded corners
            }
        );
    }

    return newBarChartData;
};


/**
 * Finds the single highest overall peak from the daily peaks data across all clamps.
 * 
 * @param {Object} formatted_hourly_data - The formatted hourly data.
 * @param {boolean} isThreePhase - Indicates whether the data is for a three-phase system.
 * @returns {Object} - The date and value of the highest peak.
 */
const findHighestOverallPeak = (formatted_hourly_data, isThreePhase) => {
    const dailyPeaks = aggregateDailyData(formatted_hourly_data);
    let highestPeak = { date: null, maxWatt: 0 };

    const clamps = ['clamp1watt', 'clamp2watt', 'clamp3watt'];

    if (isThreePhase) {
        // If three-phase, find the single highest peak across all clamps
        clamps.forEach(clamp => {
            for (const [date, data] of Object.entries(dailyPeaks[clamp])) {
                if (data.maxWatt > highestPeak.maxWatt) {
                    highestPeak = { date, maxWatt: data.maxWatt };
                }
            }
        });
    } else {
        // If not three-phase, sum the peaks from all clamps for each day
        const summedPeaks = {};

        // Aggregate summed peaks by date
        clamps.forEach(clamp => {
            for (const [date, data] of Object.entries(dailyPeaks[clamp])) {
                if (!summedPeaks[date]) {
                    summedPeaks[date] = 0;
                }
                summedPeaks[date] += data.maxWatt;
            }
        });

        // Find the highest summed peak
        for (const [date, totalMaxWatt] of Object.entries(summedPeaks)) {
            if (totalMaxWatt > highestPeak.maxWatt) {
                highestPeak = { date, maxWatt: totalMaxWatt };
            }
        }
    }

    console.log("Highest Peak", highestPeak);
    return highestPeak;
};




/**
 * Generates weekly simulation chart data.
 * 
 * @param {Array} newRawHourlyReadings - The raw hourly readings.
 * @param {string} startDate - The start date for the data range.
 * @param {string} endDate - The end date for the data range.
 * @returns {Array} - The formatted data for the weekly simulation chart.
 */
export const weeklySimulationChartData = (newRawHourlyReadings) => {
    const newWeeklyConsumptionData = [];



    const combined_hourly_readigs = formatHourlyRawDataCombined(newRawHourlyReadings); // Combine wattages of all clamps


    
     // Format the raw hourly readings
     const formattedHourlyData = combined_hourly_readigs.reduce((acc, reading) => {
        const date = dayjs(reading.time).tz('Etc/GMT').format('YYYY-MM-DD');
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push({
            time: reading.time,
            totalWatt: reading.max,
            maxedWatt: reading.maximum
        });
        return acc;
    }, {});


    

    // Initialize weekly data arrays
    const weeklyData = Array(7).fill(null).map(() => Array(24).fill(0));
    const countData = Array(7).fill(null).map(() => Array(24).fill(0));

    // populate each weekly day array with the totalWatt for each hour of the day
    const dates = Object.keys(formattedHourlyData);
    dates.forEach(date => {
        // check what day of week that date is
        const dayOfWeek = dayjs(date).day(); // 0 (Sunday) to 6 (Saturday)
        const hourlyData = formattedHourlyData[date];

        if (hourlyData) {
            hourlyData.forEach(hourData => {
                const hour = dayjs(hourData.time).hour();
                weeklyData[dayOfWeek][hour] += hourData.totalWatt;
                countData[dayOfWeek][hour] += 1;
            });
        }
    });

    // Calculate total consumption per day
    const dailyConsumptions = Object.keys(formattedHourlyData).map(date => {
        const hourlyData = formattedHourlyData[date];
        const totalDailyConsumption = hourlyData.reduce((acc, hourData) => acc + hourData.totalWatt, 0) / 1000; // Convert to kWh
        return totalDailyConsumption;
    });

    // console.log("DEBUG - dailyConsumptions", dailyConsumptions);

    // Calculate average daily consumption
    const singleValueDailyAvg = dailyConsumptions.reduce((acc, dailyTotal) => acc + dailyTotal, 0) / dailyConsumptions.length;

    // console.log("DEBUG - averageDailyConsumption", singleValueDailyAvg);





    // Calculate averages
    const averageWeeklyData = weeklyData.map((dayData, dayIndex) => {
        return dayData.map((hourData, hourIndex) => {
            return countData[dayIndex][hourIndex] > 0 ? (hourData / countData[dayIndex][hourIndex])/1000 : 0;
        });
    });







    // calculate overall average per day
    // const overallAverage = averageWeeklyData.map(dayData => {
    //     console.log("DEBUG - dayData", dayData);
    //     return dayData.reduce((acc, hourData) => acc + hourData, 0) / 24;
    // });

    // const overallAverage = averageWeeklyData.map(dayData => {
    //     // console.log("DEBUG - dayData", dayData);
    //     const nonZeroValues = dayData.filter(hourData => hourData > 0);
    //     const total = nonZeroValues.reduce((acc, hourData) => acc + hourData, 0);
    //     const count = nonZeroValues.length;
    //     return count > 0 ? total / count : 0;
    // });

    // const singleValueDailyAvg = overallAverage.reduce((acc, dailyAvg) => acc + dailyAvg, 0) / overallAverage.length; // overall average per day in the week in kWh



   

    // Generate labels and values for the chart
    const xLabels = [];
for (let day = 0; day < 7; day++) {
    for (let hour = 0; hour < 24; hour++) {
        xLabels.push(`${['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'][day]} ${hour}:00`);
    }
}

    const yValues = averageWeeklyData.flat();

    newWeeklyConsumptionData.push({
        x: xLabels,
        y: yValues,
        type: 'line',
        marker: { color: '#1F77B4' },
        hoverinfo: 'x+y',
        name: 'kW',
        text: yValues,
        textposition: 'top',
        insidetextanchor: 'middle'
    });


    const highestPeak = findHighestOverallPeak(formatHourlyRawData(newRawHourlyReadings, true)).maxWatt / 1000;

    return {newWeeklyConsumptionData, highestPeak, singleValueDailyAvg};
};


/**
 * Calculates the consumption projections graph data.
 * 
 * @param {Array} newRawHourlyReadings - The operating capacity projections.
 * @returns {Array} - The formatted data for the consumption projections chart.
 */
export const calculateConsumptionAvgCapacityGraphData = (newRawHourlyReadings) => {
    const operatingCapacityProjections = formatHourlyRawDataCombined(newRawHourlyReadings);

    // Find the maximum value using the correct method
    const maxRecordedValue = findHighestOverallPeak(formatHourlyRawData(newRawHourlyReadings, true)).maxWatt / 1000;

    console.log("MAX VALUEE", maxRecordedValue);

    // Calculate each value as a percentage of the maximum value
    const percentageValues = operatingCapacityProjections.map(projection => ((projection.maximum/1000) / maxRecordedValue) * 100);

    // Define the ranges
    const ranges = [
        { low: 0, high: 25 },
        { low: 25, high: 50 },
        { low: 50, high: 75 },
        { low: 75, high: 100000 }
    ];
    const rangeCounts = Array(ranges.length).fill(0);

    // Count the number of projections in each range
    percentageValues.forEach(projection => {
        ranges.forEach((range, index) => {
            if (range.low <= projection && projection <= range.high) {
                rangeCounts[index] += 1;
            }
        });
    });

    // Calculate the percentage of time in each range
    const totalProjections = percentageValues.length;
    const percentageTimeInRanges = rangeCounts.map(count => (count / totalProjections) * 100);

    // Generate labels and values for the chart
    const peakKW = maxRecordedValue;
    const quadrantRange = peakKW / 4;
    const xLabels = [
        `0kW - ${quadrantRange.toFixed(2)}kW`,
        `${(quadrantRange + 0.01).toFixed(2)}kW - ${(2 * quadrantRange).toFixed(2)}kW`,
        `${(2 * quadrantRange + 0.01).toFixed(2)}kW - ${(3 * quadrantRange).toFixed(2)}kW`,
        `${(3 * quadrantRange + 0.01).toFixed(2)}kW - ${peakKW.toFixed(2)}kW`
    ];
    const yValues = percentageTimeInRanges;

    const chartData = [{
        x: xLabels,
        y: yValues,
        type: 'bar',
        marker: { color: '#1F77B4' },
        hoverinfo: 'x+y',
        name: 'Percentage Time in Range',
        text: yValues.map(value => `${value.toFixed(2)}%`),
        textposition: 'auto',
        insidetextanchor: 'middle'
    }];

    return chartData;
};

// Example usage
//const operatingCapacityProjections = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];


//  USED IN MONITORS PAGE
export const lineChartData = (currentMonitorData, currentDay, format = 'minutely') => {
    const clamp1Data = currentMonitorData?.data?.clamp1watt?.data || []; 
    const clamp2Data = currentMonitorData?.data?.clamp2watt?.data || [];
    const clamp3Data = currentMonitorData?.data?.clamp3watt?.data || [];

    const aggregatedClamp1 = aggregateData(clamp1Data, currentDay, format);
    const aggregatedClamp2 = aggregateData(clamp2Data, currentDay, format);
    const aggregatedClamp3 = aggregateData(clamp3Data, currentDay, format);

    const formatDataForChart = (aggregatedData, color, text, type = 'scatter', mode = 'lines') => {
        const times = Object.keys(aggregatedData);
        const values = times.map(time => roundTo(aggregatedData[time].totalWatt / 1000, 2)); // Convert to kW

        return {
            x: times,
            y: values,
            type: type,
            mode: mode,
            marker: { color: `rgba(${color}, 0.8)`, size: 8, symbol: 'circle' },
            line: { shape: 'spline', smoothing: 0 },
            text: values.map(value => `${value}`), // Always show only the value
            customdata: Array(times.length).fill(text), // Store the clamp text in customdata
            hovertemplate: type === 'bar' ? `
                %{customdata}<br>
                Value: %{y:.2f} kWH<br>
                Time: %{x| %H:%M}<br>
                <extra></extra>
            ` : `
                <b>%{customdata}</b><br>
                Time: %{x|%H:%M}<br>
                Day: %{x|%d}<br>
                Value: %{y:.2f} kW<br>
                <extra></extra>
            `,
            name: text, // This will be used in the legend
            hovermode: "closest",
            hoverlabel: { bgcolor: "#FFF", font: { color: "#000" } }, // Set hover label text color to black
            textposition: 'inside',
            insidetextanchor: 'middle',
            textangle: 0, // Force text to be displayed horizontally flat
        };
    };

    const chartData = [];
    if (format === 'hourly') {
        chartData.push(formatDataForChart(aggregatedClamp1, '220, 78, 78', 'Clamp 1', 'bar', undefined));
        chartData.push(formatDataForChart(aggregatedClamp2, '31, 119, 180', 'Clamp 2', 'bar', undefined));
        chartData.push(formatDataForChart(aggregatedClamp3, '241, 225, 91', 'Clamp 3', 'bar', undefined));
    } else {
        chartData.push(formatDataForChart(aggregatedClamp1, '220, 78, 78', 'Clamp 1'));
        chartData.push(formatDataForChart(aggregatedClamp2, '31, 119, 180', 'Clamp 2'));
        chartData.push(formatDataForChart(aggregatedClamp3, '241, 225, 91', 'Clamp 3'));
    }

    return chartData;
};

export const newlineChartData = (currentMonitorData, currentDay, format = 'minutely') => {
    const clamp1Data = currentMonitorData?.data?.clamp1watt?.data || [];
    const clamp2Data = currentMonitorData?.data?.clamp2watt?.data || [];
    const clamp3Data = currentMonitorData?.data?.clamp3watt?.data || [];

    const aggregatedClamp1 = aggregateData(clamp1Data, currentDay, format);
    const aggregatedClamp2 = aggregateData(clamp2Data, currentDay, format);
    const aggregatedClamp3 = aggregateData(clamp3Data, currentDay, format);

    const formatDataForChart = (aggregatedData, color, name) => {
        const times = Object.keys(aggregatedData); 
        return times.map(time => ({
            time: time,
            value: roundTo(aggregatedData[time].totalWatt / 1000, 2), // Convert to kW
            category: name,


        }));
    };

    const chartData = [
        ...formatDataForChart(aggregatedClamp1, 'red', 'Clamp 1 kW'),
        ...formatDataForChart(aggregatedClamp2, '#5799C7', 'Clamp 2 kW'),
        ...formatDataForChart(aggregatedClamp3, '#F5E984', 'Clamp 3 kW'),
    ];

    return chartData;
};



